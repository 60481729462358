import React from 'react';

import styles from './searchDropdown.module.scss';

export type SearchDropdownProps = {
  searchResults: { name: string }[];
  handleClick: (name: string) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
};

const SearchDropdown = ({ searchResults, handleClick, dropdownRef }: SearchDropdownProps) => (
  <>
    <div className={styles['dropdown-menu']} ref={dropdownRef}>
      <ul className={styles['dropdown-menu-list']}>
        {searchResults.map(item => {
          const { name } = item;
          return (
            <li className={styles['dropdown-menu-item']} key={name} onClick={() => handleClick(name)}>
              {name}
            </li>
          );
        })}
      </ul>
    </div>
  </>
);
export default SearchDropdown;
