import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './previewUser.module.scss';
import AvatarWithTier from 'src/components/atoms/AvatarWithTier';

export type PreviewUserProps = {
  tier: number;
  image: string;
  name: string;
  loading: boolean;
  size?: 'sm' | 'lg' | 'md' | 'ml';
  onCard?: boolean;
  color?: string;
};

const PreviewUser = ({ tier, image, name, loading, size, onCard, color }: PreviewUserProps) => {
  return (
    <>
      <div className={styles['preview-user']}>
        <a className={styles['preview-user-overlay']} href="/"></a>
        <div className={styles['preview-user-content']}>
          <div className={styles['preview-user-avatar']} style={{ marginRight: onCard ? '4px' : '' }}>
            <AvatarWithTier image={image} tier={tier} loading={loading} size={size} onCard={onCard} />
          </div>
          <span className={styles['preview-user-name']} style={{ color }}>
            {loading ? <Skeleton count={1} width={60} height={20} /> : name}
          </span>
        </div>
      </div>
    </>
  );
};
export default PreviewUser;
