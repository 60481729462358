import React from 'react';
import cx from 'classnames';

import LandingHeaderContent from 'src/components/organisms/LandingHeaderContent';
import LandingBlitzCard, { LandingBlitzCardProps } from 'src/components/molecules/LandingBlitzCard';

import { sizes } from 'src/constants/screenSizes';
import useMediaQuery from 'src/hooks/useMediaQuery';

import styles from './blitz.module.scss';

const {
  newBadgeStyling,
  blitzCardMainContainer,
  blitzCardWrapper,
  puzzleCard,
  blitzTextWrapper,
  blitzText,
  blitzTextMobile,
  connectedArrow,
} = styles;

export type BlitzProps = {
  blitzCardData: LandingBlitzCardProps;
  loading: boolean;
};

const Blitz = ({ blitzCardData, loading }: BlitzProps) => {
  const {
    title,
    titleSolution,
    image,
    imageSolution,
    username,
    url,
    urlSolution,
    description,
    descriptionSolution,
    tag1,
    tag2,
    submissionsCount,
    userImage,
    likeCount,
    tier,
  } = blitzCardData;

  const isXL = useMediaQuery(sizes.xLarge);
  const isL = useMediaQuery(sizes.large);
  const isS = useMediaQuery(sizes.small);

  const blitzDescription = `Hands-on AI puzzles for beginners and coders, 
  focused on making learning AI fun, practical and straight-forward.
  Host your puzzle solutions as apps and build a stellar ML Portfolio.`;

  return (
    <>
      <div>
        <span className={newBadgeStyling}>NEW</span>
        <LandingHeaderContent
          title="Blitz"
          description={blitzDescription}
          buttonText="Join Blitz"
          url="/blitz"
          logo
          descriptionWidth={isL ? '100%' : isXL ? '80%' : '80%'}
        />
      </div>
      <div className={blitzCardMainContainer}>
        <div className={blitzCardWrapper}>
          <div className={puzzleCard}>
            <div className={blitzTextWrapper}>
              <img src="https://assets.aicrowd.com/assets/img/flash.svg"></img>
              <span className={blitzText}>Solve AI Puzzles</span>{' '}
            </div>
            <LandingBlitzCard
              title={title}
              image={image}
              url={url}
              description={description}
              tag1={tag1}
              tag2={tag2}
              submissionsCount={submissionsCount}
              appCard={false}
            />
          </div>
          <img src={`https://assets.aicrowd.com/assets/img/${isS ? 'blitzArrowMobile' : 'blitzArrow'}.svg`} className={cx(connectedArrow)}></img>
          <div>
            {/* Show only on small screens */}
            <div
              className={cx(blitzTextWrapper, 'hide-sm-up')}
              style={{ justifyContent: 'flex-end', display: 'flex', paddingTop: '40px' }}>
              <img src="https://assets.aicrowd.com/assets/img/flash.svg"></img>
              <span className={cx(blitzText, blitzTextMobile)}>Learn From Experts</span>{' '}
            </div>
            <LandingBlitzCard
              appCard={true}
              title={titleSolution}
              description={descriptionSolution}
              image={imageSolution}
              username={username}
              url={urlSolution}
              userImage={userImage}
              likeCount={likeCount}
              tier={tier}
            />
            {/* Show only on large screens */}
            <div className={cx(blitzTextWrapper, 'hide-sm')}>
              <img src="https://assets.aicrowd.com/assets/img/flash.svg"></img>
              <span className={blitzText}>Learn From Experts</span>{' '}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blitz;
