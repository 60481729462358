import React from 'react';

const Link = ({ children }) => {
  // THIS IS JUST DUMMY COMPONENT CREATED ONLY FOR USING IT ON RAILS SIDE.
  // TO REPLACE NEXT.JS LINK COMPONENT WITH THIS COMPONENT
  // THIS COMPONENT WILL BE USED ON RAILS SIDE.

  return (
    <>
      <>{children}</>
    </>
  );
};

export default Link;
