import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import cx from 'classnames';

import styles from './formInput.module.scss';
const {
  focusBorder,
  formError,
  colorError,
  focusBorderError,
  inputIconContainer,
  iconStyling,
  iconInputStyling,
} = styles;

export type FormInputProps = {
  /** Type is textarea & search default is text if nothing is passed */
  type: 'textarea' | 'textbox' | 'textbox-search' | 'number' | 'email' | 'file';
  placeholder?: string;
  /** If field type is of password */
  isPassword?: boolean;
  rows?: number;
  register?: UseFormRegister<any>;
  name?: string;
  handleChange?: (e: any) => void;
  handleEnter?: (e: any) => void;
  handleOnClick?: (e: any) => void;
  disableAnimation?: boolean;
  maxLength?: number;
  error?: boolean | string;
  value?: string;
  selectedFileName?: string;
  progress?: number;
  icon?: string; // TODO: to add icons for input fields
  handleKeyPress?: (e: any) => void;
};

const FormInput = ({
  type,
  placeholder,
  isPassword,
  rows,
  register,
  name,
  handleChange,
  handleEnter,
  handleOnClick,
  maxLength,
  error,
  value,
  selectedFileName,
  progress,
  icon,
  handleKeyPress,
}: FormInputProps) => {
  return (
    <>
      {type === 'textarea' && (
        <>
          <div style={{ position: 'relative', width: '100%' }}>
            {register ? (
              <textarea
                className={cx(styles['textarea'], { [colorError]: error })}
                placeholder={placeholder}
                rows={rows || 4}
                maxLength={maxLength}
                onKeyUp={handleKeyPress}
                {...register(name, { value })}></textarea>
            ) : (
              <textarea
                className={cx(styles['textarea'], { [colorError]: error })}
                placeholder={placeholder}
                rows={rows || 4}
                maxLength={maxLength || 100}
                value={value}></textarea>
            )}

            <span className={focusBorder}>
              <i></i>
            </span>
          </div>
          <p className={cx(formError, 'bounce')}>{error}</p>
        </>
      )}

      {type !== 'textarea' && (
        <>
          <div style={{ position: 'relative' }}>
            <div className={inputIconContainer}>
              {/* <i className={cx('las la-grin-alt', iconStyling)}></i> */}
              {register ? (
                <input
                  {...register(name)}
                  className={cx(styles[`${['textbox', 'textbox-search'].includes(type) ? type : 'textbox'}`], {
                    [colorError]: error,
                    [iconInputStyling]: icon,
                  })}
                  type={isPassword ? 'password' : type?.includes('search') ? 'search' : type || 'text'}
                  placeholder={placeholder}></input>
              ) : (
                <input
                  value={value}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleEnter(e)
                    }
                  }}
                  onClick={e => handleOnClick(e)}
                  onChange={e => handleChange(e)}
                  className={cx(styles[`${['textbox', 'textbox-search'].includes(type) ? type : 'textbox'}`], {
                    [colorError]: error,
                  })}
                  type={isPassword ? 'password' : type?.includes('search') ? 'search' : type || 'text'}
                  placeholder={placeholder}></input>
              )}
            </div>
            <span className={cx(error ? focusBorderError : focusBorder)}>
              <i></i>
            </span>
          </div>
          <p className={cx(formError, { ['bounce']: error })}>{error}</p>
        </>
      )}
    </>
  );
};

export default FormInput;
