import React from 'react';
import Link from 'src/components/atoms/Link';

import styles from './landingBlitzCard.module.scss';
import PreviewUser from '../PreviewUser';

const {
  mainCardWrapper,
  titleText,
  subWrapper,
  textWrapper,
  descriptionText,
  imgWrapper,
  footerWrapper,
  footerText,
  tagWrapper,
  tag,
  mainStatWrapper,
  subStatWrapper,
  wrapper,
} = styles;

type LandingBlitzCardCommonProps = {
  title: string;
  description: string;
  image: string;
  url: string;
  loading?: boolean;
  imageSolution?: string;
  titleSolution?: string;
  urlSolution?: string;
  descriptionSolution?: string;
};

type LandingPuzzleCardProps = LandingBlitzCardCommonProps & {
  tag1: string;
  tag2: string;
  submissionsCount: number;
  appCard: false | undefined;
  username?: never;
  userImage?: never;
  likeCount?: never;
  tier?: never;
};

type LandingBlitzAppCardProps = LandingBlitzCardCommonProps & {
  appCard: true;
  username: string;
  userImage: string;
  likeCount: number;
  tier: number;
  tag1?: never;
  tag2?: never;
  submissionsCount?: never;
};

export type LandingBlitzCardProps = LandingPuzzleCardProps | LandingBlitzAppCardProps;

const LandingBlitzCard = ({
  title,
  image,
  username,
  url,
  description,
  tag1,
  tag2,
  submissionsCount,
  appCard,
  loading,
  userImage,
  likeCount,
  tier,
}: LandingBlitzCardProps) => {
  return (
    <>
      <a href={url}>
        {/* <a> */}
        <div className={mainCardWrapper} style={{ height: appCard ? '322px' : 'auto' }}>
          <div className={imgWrapper} style={{ height: appCard ? '340px' : '158px' }}>
            <img
              src={image}
              placeholder="blur"
              layout="fill"
              objectFit="cover"
              alt="notebook image"
            />
          </div>
          {appCard ? null : (
            <div className={tagWrapper}>
              <span className={tag}>{tag1}</span>
              <span className={tag}>{tag2}</span>
            </div>
          )}
          <div className={wrapper}>
            <div className={subWrapper}>
              <div className={textWrapper}>
                <div className={titleText}>{title}</div>
                <div className={descriptionText} style={{ paddingTop: appCard ? '0px' : '' }}>
                  {description}
                </div>
              </div>
            </div>
            <div className={footerWrapper}>
              {appCard ? null : (
                <span className={footerText}>{submissionsCount}+ Submissions</span>
              )}
            </div>
          </div>
        </div>
        {/* </a> */}
      </a>
    </>
  );
};

export default LandingBlitzCard;

